import { AuctionToUpdate } from '@/staff/domain/exclusive/AuctionToUpdate';

export interface BackendAuctionToUpdate {
  startDate?: string;
  endDate?: string;
}

export const toBackendAuctionToUpdate = (auction: AuctionToUpdate): BackendAuctionToUpdate => ({
  startDate: auction.startDate.map(date => date.toISOString()).orElseUndefined(),
  endDate: auction.endDate.map(date => date.toISOString()).orElseUndefined(),
});

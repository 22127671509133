import { GiveawayAnswer } from '@/staff/domain/club/giveaway/GiveawayAnswer';
import { GiveawayAnswerToCreate } from '@/staff/domain/club/giveaway/GiveawayAnswerToCreate';
import { GiveawayAnswerToUpdate } from '@/staff/domain/club/giveaway/GiveawayAnswerToUpdate';
import { Optional } from '@/common/domain/Optional';

export interface GiveawayAnswerUi {
  id?: string;
  text: string;
}

export const fromGiveawayAnswer = (giveawayAnswer: GiveawayAnswer): GiveawayAnswerUi => ({
  id: giveawayAnswer.id,
  text: giveawayAnswer.text,
});

export const toGiveawayAnswerToCreate = (giveawayAnswerUi: GiveawayAnswerUi): GiveawayAnswerToCreate => ({
  text: giveawayAnswerUi.text,
});

export const toGiveawayAnswerToUpdate = (giveawayAnswerUi: GiveawayAnswerUi): GiveawayAnswerToUpdate => ({
  id: Optional.ofUndefinable(giveawayAnswerUi.id),
  text: giveawayAnswerUi.text,
});
export const emptyAnswerUi = (): GiveawayAnswerUi => ({
  text: '',
});

import { PostToUpdate } from '@/staff/domain/post/PostToUpdate';
import { BackendMedia, toBackendMedia } from '@/staff/secondary/BackendMedia';
import { Optional } from '@/common/domain/Optional';
import { Media } from '@/common/domain/Media';
import { BackendTranslation, fromTranslation } from '@/common/secondary/BackendTranslation';
import { PostName } from '@/staff/domain/post/PostName';
import { PostDescription } from '@/staff/domain/post/PostDescription';

export interface BackendPostToUpdate {
  slug?: string;
  imageUrl?: string;
  medias?: BackendMedia[];
  nameTranslations: BackendTranslation[];
  descriptionTranslations: BackendTranslation[];
}

const toBackendPostToUpdateMedias = (medias: Optional<Media[]>): BackendMedia[] | undefined => {
  if (medias.isEmpty()) {
    return undefined;
  }
  return medias.map(mediaList => mediaList.map(toBackendMedia)).orElseUndefined();
};

export const toBackendPostToUpdate = (postToUpdate: PostToUpdate): BackendPostToUpdate => ({
  slug: postToUpdate.slug.orElseUndefined(),
  imageUrl: postToUpdate.imageUrl.orElseUndefined(),
  medias: toBackendPostToUpdateMedias(postToUpdate.medias),
  nameTranslations: postToUpdate.nameTranslations.map(fromTranslation<PostName>),
  descriptionTranslations: postToUpdate.descriptionTranslations.map(fromTranslation<PostDescription>),
});

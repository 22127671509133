import { fromTokens, TokensUi } from '@/common/primary/token/Tokens.ui';
import { Exclusive } from '@/staff/domain/exclusive/Exclusive';
import { ExclusiveCategory } from '@/staff/domain/exclusive/ExclusiveCategory';
import { fromTranslation, TranslationUi } from '@/common/primary/Translation.ui';
import { AuctionUi, fromAuction } from '@/staff/primary/exclusive/Auction.ui';

type CategoryIcon = 'fp-glyph-handshake' | 'fp-glyph-award';

export interface ExclusiveUi {
  name: string;
  imageUrl: string;
  description: string;
  price: TokensUi;
  visible: boolean;
  stock: number;
  category: ExclusiveCategory;
  categoryIcon: CategoryIcon;
  slug: string;
  auction?: AuctionUi;
  nameTranslations?: TranslationUi[];
  descriptionTranslations?: TranslationUi[];
}

const toCategoryIcon = (category: ExclusiveCategory): CategoryIcon => {
  switch (category) {
    case ExclusiveCategory.EXPERIENCE:
      return 'fp-glyph-handshake';
    case ExclusiveCategory.COLLECTIBLE:
      return 'fp-glyph-award';
  }
};

export const fromExclusive = (exclusive: Exclusive): ExclusiveUi => ({
  name: exclusive.name,
  imageUrl: exclusive.imageUrl,
  description: exclusive.description,
  price: fromTokens(exclusive.pricing),
  visible: exclusive.visible,
  stock: exclusive.stock,
  category: exclusive.category,
  categoryIcon: toCategoryIcon(exclusive.category),
  slug: exclusive.slug,
  auction: exclusive.auction.map(fromAuction).orElseUndefined(),
  nameTranslations: exclusive.nameTranslations?.map(fromTranslation),
  descriptionTranslations: exclusive.descriptionTranslations?.map(fromTranslation),
});

import { InjectionKey } from 'vue';
import { Unsubscribe } from '@/common/domain/Unsubscribe';
import { BusCallback } from '@/common/domain/BusCallback';
import { SidebarOpened } from '@/common/domain/sidebar/SidebarOpened';

export const sidebarBusKey: InjectionKey<SidebarBus> = Symbol();

export interface SidebarBus {
  open(sidebar: SidebarOpened): void;
  close(): void;
  opened(callback: BusCallback<SidebarOpened>): Unsubscribe;
  closed(callback: BusCallback<void>): Unsubscribe;
}

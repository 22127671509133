include ../../club/loading-table.mixin.pug

template(v-if='postsList.isLoading()')
  +loading-table
template(v-else)
  p(v-if='!hasPosts' data-test='no-posts') {{ t('postsPage.noPosts') }}
  .fp-vertical-spacing(v-else)
    .fp-vertical-spacing--line
      table.fp-table(data-test='posts-table')
        thead
          tr
            th {{ t('postsPage.table.date') }}
            th {{ t('postsPage.table.slug') }}
            th {{ t('postsPage.table.name') }}
            th {{ t('postsPage.table.program') }}
            th.-shrink
        tbody
          tr(v-for='(post, index) in postsList.value()' :key='index' :data-test='`post-${index}`')
            td
              code {{ post.date.toHuman() }}
            td(:title='post.name')
              .fp-icon-with-text
                router-link.fp-link(:to='{ name: \'updatePost\', params: { clubSlug: club.slug, postSlug: post.slug  } }')
                  span.fp-text(data-test='slug') {{ post.slug }}
            td {{ post.name }}
            td
              span(v-if='post.programSlug' data-test='programSlug') {{ post.programSlug }}
              span.fp-text.-color-shade-400(v-else data-test='no-program') {{ t('postsPage.noPrograms') }}
            td
              DropdownVue(:name='`actions-post-${post.slug}`')
                DropdownItemVue(:id='post.slug' :text='t(\'postsPage.actions.update\')' icon='edit' :routeLocation='{ name: \'updatePost\', params: { clubSlug: club.slug, postSlug: post.slug  } }' :data-test='`edit-post-${post.slug}`')
                DropdownSeparatorVue
                DropdownItemVue(:text='t(\'postsPage.actions.see\')' icon='arrow-square-in' :href='`${club.webappUrl}/news/${post.slug}`' :data-test='`go-to-post-${post.slug}`')

import { inject, onMounted, onUnmounted, ref } from 'vue';
import { Unsubscribe } from '@/common/domain/Unsubscribe';
import { sidebarBusKey } from '@/common/domain/sidebar/SidebarBus';
import { SidebarOpened } from '@/common/domain/sidebar/SidebarOpened';
import { globalWindowKey } from '@/common/domain/Window';

export default {
  name: 'Sidebar',

  setup() {
    const globalWindow = inject(globalWindowKey)!;
    const sidebarBus = inject(sidebarBusKey)!;

    const isClosing = ref<boolean>(false);
    const isSidebarOpened = ref<boolean>(false);
    const sidebar = ref();
    let unsubscribeOpenSidebar: Unsubscribe;
    let unsubscribeCloseSidebar: Unsubscribe;

    onMounted(async () => {
      unsubscribeOpenSidebar = sidebarBus.opened(openSidebar);
      unsubscribeCloseSidebar = sidebarBus.closed(closeSidebar);
    });

    onUnmounted(() => {
      unsubscribeOpenSidebar();
      unsubscribeCloseSidebar();
    });

    const openSidebar = (sidebarOpened: SidebarOpened) => {
      sidebar.value = sidebarOpened;
      isSidebarOpened.value = true;
    };

    const closeSidebar = () => {
      isClosing.value = true;
      globalWindow.setTimeout(() => {
        isSidebarOpened.value = false;
        isClosing.value = false;
      }, 500);
    };

    return {
      isSidebarOpened,
      sidebar,
      isClosing,
      openSidebar,
      closeSidebar,
    };
  },
};

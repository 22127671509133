import { Loader } from '@/common/primary/loader/Loader';
import { clubRepositoryKey } from '@/staff/domain/club/ClubRepository';
import { CoinsGiftsUi, emptyCoinsGifts, fromCoinsGifts } from '@/staff/primary/club/coins-gifts-table/CoinsGifts.ui';
import { computed, defineComponent, inject, onMounted, ref } from 'vue';
import { useI18n } from 'vue-i18n';
import { CoinsGiftStatus } from '@/staff/domain/club/CoinsGiftStatus';
import { loggerKey } from '@/common/domain/Logger';
import { fromClub } from '@/staff/primary/club/Club.ui';
import { ExclusivePriceVue } from '../../exclusive/exclusive-price';
import { FairplayerButtonVue } from '@/common/primary/button';
import { modalBusKey } from '@/common/domain/modal/ModalBus';
import { giveCoinsModal } from '@/common/primary/modal/Modals';

export default defineComponent({
  name: 'CoinsGiftTable',

  components: { ExclusivePriceVue, FairplayerButtonVue },

  setup() {
    const { t } = useI18n();
    const logger = inject(loggerKey)!;
    const clubRepository = inject(clubRepositoryKey)!;
    const modalBus = inject(modalBusKey)!;

    const coinsGifts = ref(Loader.loading<CoinsGiftsUi>());
    const hasCoinsGifts = computed(() => !coinsGifts.value.isLoading() && coinsGifts.value.value().coinGifts.length > 0);
    const showExpiredCoinsGifts = ref(true);

    const club = fromClub(clubRepository.getCurrentClub());

    onMounted(() => listCoinsGifts());

    const listCoinsGifts = () => {
      clubRepository
        .getCoinsGifts(club.slug, club.coin)
        .then(clubRepository => coinsGifts.value.loaded(fromCoinsGifts(clubRepository)))
        .catch(error => {
          coinsGifts.value.loaded(emptyCoinsGifts());
          logger.error('Failed to retrieve coinsGifts', error);
        });
    };

    const filteredCoinsGifts = computed(() => {
      if (showExpiredCoinsGifts.value) {
        return coinsGifts.value.value().coinGifts;
      }

      return coinsGifts.value.value().coinGifts.filter(gift => gift.status !== CoinsGiftStatus.EXPIRED);
    });

    const giveCoins = () => {
      const modal = giveCoinsModal();
      modalBus.open({
        component: modal,
        options: {
          gifted: listCoinsGifts,
        },
      });
    };

    return {
      club,
      coinsGifts,
      filteredCoinsGifts,
      showExpiredCoinsGifts,
      hasCoinsGifts,
      t,
      giveCoins,
    };
  },
});

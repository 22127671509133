import { AuctionToCreate } from '@/staff/domain/exclusive/AuctionToCreate';

export interface BackendAuctionToCreate {
  startDate: string;
  endDate: string;
}

export const toBackendAuctionToCreate = (auction: AuctionToCreate): BackendAuctionToCreate => ({
  startDate: auction.startDate.toISOString(),
  endDate: auction.endDate.toISOString(),
});

import {
  emptyAnswerUi,
  fromGiveawayAnswer,
  GiveawayAnswerUi,
  toGiveawayAnswerToCreate,
  toGiveawayAnswerToUpdate,
} from '@/staff/primary/giveaway/GiveawayAnswer.ui';
import { GiveawayQuestionToCreate } from '@/staff/domain/club/giveaway/GiveawayQuestionToCreate';
import { GiveawayQuestionToUpdate } from '@/staff/domain/club/giveaway/GiveawayQuestionToUpdate';
import { GiveawayQuestion } from '@/staff/domain/club/giveaway/GiveawayQuestion';
import { Optional } from '@/common/domain/Optional';
import { GiveawayQuestionTypeUi } from '@/staff/primary/giveaway/create-giveaway/GiveawayQuestionType.ui';
import { GiveawayAnswerToCreate } from '@/staff/domain/club/giveaway/GiveawayAnswerToCreate';
import { GiveawayAnswerToUpdate } from '@/staff/domain/club/giveaway/GiveawayAnswerToUpdate';
import { v4 as uuidv4 } from 'uuid';
import { emptyMarkdownTextUi, MarkdownTextUi } from '@/common/primary/markdown/MarkdownText.ui';
import { toRaw } from 'vue';

const FREE_TEXT_TYPES = [GiveawayQuestionTypeUi.PARAGRAPH, GiveawayQuestionTypeUi.TEXT];

export interface GiveawayQuestionFormUi {
  id?: string;
  randomKey: string;
  title: string;
  preTitle: MarkdownTextUi;
  answers: GiveawayAnswerUi[];
  rightAnswer?: GiveawayAnswerUi;
  type: GiveawayQuestionTypeUi;
  required: boolean;
  quizMode: boolean;
}

const toGiveawayQuestionTypeUi = (giveawayQuestion: GiveawayQuestion): GiveawayQuestionTypeUi => {
  if (giveawayQuestion.answers.length !== 0) {
    return GiveawayQuestionTypeUi.CHOICE;
  }

  if (giveawayQuestion.multiline) {
    return GiveawayQuestionTypeUi.PARAGRAPH;
  }

  return GiveawayQuestionTypeUi.TEXT;
};

const toRightAnswerUi = (giveawayQuestion: GiveawayQuestion, answers: GiveawayAnswerUi[]): GiveawayAnswerUi | undefined => {
  if (giveawayQuestion.rightAnswer.isEmpty()) {
    return undefined;
  }
  if (giveawayQuestion.answers.length === 0) {
    return emptyAnswerUi();
  }

  return giveawayQuestion.rightAnswer.map(answer => answers.find(a => a.id === answer.id)!).orElse(emptyAnswerUi());
};

export const fromGiveawayQuestion = (giveawayQuestion: GiveawayQuestion): GiveawayQuestionFormUi => {
  const answers = giveawayQuestion.answers.map(fromGiveawayAnswer);

  return {
    id: giveawayQuestion.id,
    randomKey: uuidv4(),
    title: giveawayQuestion.title,
    answers: answers,
    rightAnswer: toRightAnswerUi(giveawayQuestion, answers),
    type: toGiveawayQuestionTypeUi(giveawayQuestion),
    required: giveawayQuestion.required,
    quizMode: giveawayQuestion.quizMode,
    preTitle: giveawayQuestion.preTitle
      .map(preTitle => ({
        value: preTitle,
      }))
      .orElse(emptyMarkdownTextUi()),
  };
};

const toGiveawayQuestionAnswersToCreate = (giveawayQuestionFormUi: GiveawayQuestionFormUi): GiveawayAnswerToCreate[] => {
  if (freeTextQuestion(giveawayQuestionFormUi)) {
    return [];
  }

  return giveawayQuestionFormUi.answers.map(toGiveawayAnswerToCreate);
};

const toGiveawayQuestionRightAnswerToCreate = (giveawayQuestionFormUi: GiveawayQuestionFormUi): GiveawayAnswerToCreate | undefined => {
  if (freeTextQuestion(giveawayQuestionFormUi)) {
    return undefined;
  }
  if (!giveawayQuestionFormUi.quizMode) {
    return undefined;
  }
  if (!giveawayQuestionFormUi.rightAnswer) {
    return undefined;
  }
  return toGiveawayAnswerToCreate(giveawayQuestionFormUi.rightAnswer);
};
export const toGiveawayQuestionToCreate = (giveawayQuestionFormUi: GiveawayQuestionFormUi): GiveawayQuestionToCreate => ({
  title: giveawayQuestionFormUi.title,
  answers: toGiveawayQuestionAnswersToCreate(giveawayQuestionFormUi),
  rightAnswer: toGiveawayQuestionRightAnswerToCreate(giveawayQuestionFormUi),
  required: giveawayQuestionFormUi.required,
  quizMode: giveawayQuestionFormUi.quizMode,
  multiline: giveawayQuestionFormUi.type === GiveawayQuestionTypeUi.PARAGRAPH,
  preTitle: Optional.ofEmpty(giveawayQuestionFormUi.preTitle.value),
});

const toGiveawayAnswersToUpdate = (giveawayQuestionFormUi: GiveawayQuestionFormUi): GiveawayAnswerToUpdate[] => {
  if (freeTextQuestion(giveawayQuestionFormUi)) {
    return [];
  }

  return giveawayQuestionFormUi.answers.map(toGiveawayAnswerToUpdate);
};

const toGiveawayRightAnswerToUpdate = (giveawayQuestionFormUi: GiveawayQuestionFormUi): GiveawayAnswerToUpdate | undefined => {
  if (freeTextQuestion(giveawayQuestionFormUi)) {
    return undefined;
  }
  if (!giveawayQuestionFormUi.quizMode) {
    return undefined;
  }
  if (!giveawayQuestionFormUi.rightAnswer) {
    return undefined;
  }

  return toGiveawayAnswerToUpdate(giveawayQuestionFormUi.rightAnswer);
};

const freeTextQuestion = (giveawayQuestionFormUi: GiveawayQuestionFormUi) => FREE_TEXT_TYPES.includes(giveawayQuestionFormUi.type);

export const toGiveawayQuestionToUpdate = (giveawayQuestionFormUi: GiveawayQuestionFormUi): GiveawayQuestionToUpdate => ({
  id: Optional.ofUndefinable(giveawayQuestionFormUi.id),
  title: giveawayQuestionFormUi.title,
  answers: toGiveawayAnswersToUpdate(giveawayQuestionFormUi),
  rightAnswer: Optional.ofUndefinable(toGiveawayRightAnswerToUpdate(giveawayQuestionFormUi)),
  required: giveawayQuestionFormUi.required,
  quizMode: giveawayQuestionFormUi.quizMode,
  multiline: giveawayQuestionFormUi.type === GiveawayQuestionTypeUi.PARAGRAPH,
  preTitle: Optional.ofEmpty(giveawayQuestionFormUi.preTitle.value),
});

export const emptyQuestionFormUi = (): GiveawayQuestionFormUi => {
  const answers = [emptyAnswerUi(), emptyAnswerUi()];
  return {
    randomKey: uuidv4(),
    title: '',
    answers,
    rightAnswer: answers[0],
    type: GiveawayQuestionTypeUi.CHOICE,
    required: true,
    quizMode: true,
    preTitle: emptyMarkdownTextUi(),
  };
};

export const cloneQuestionFormUi = (giveawayQuestionForm: GiveawayQuestionFormUi): GiveawayQuestionFormUi => {
  const newForm = JSON.parse(JSON.stringify(toRaw(giveawayQuestionForm)));

  return {
    ...newForm,
    randomKey: uuidv4(),
  };
};

import { Auction } from '@/staff/domain/exclusive/Auction';
import { DateTimeInputUi } from '@/common/primary/date/DateTimeInput.ui';
import { AuctionToCreate } from '@/staff/domain/exclusive/AuctionToCreate';

export interface AuctionUi {
  startDate: DateTimeInputUi;
  endDate: DateTimeInputUi;
}

export const fromAuction = (auction: Auction): AuctionUi => ({
  startDate: new DateTimeInputUi(auction.startDate),
  endDate: new DateTimeInputUi(auction.endDate),
});

export const toAuctionToCreate = (auction: AuctionUi): AuctionToCreate => ({
  startDate: DateTimeInputUi.toDate(auction.startDate),
  endDate: DateTimeInputUi.toDate(auction.endDate),
});

export const emptyAuction = (): AuctionUi => {
  const currentDate = new Date();
  const tomorrowDate = new Date(new Date().setDate(currentDate.getDate() + 1));

  return {
    startDate: new DateTimeInputUi(currentDate),
    endDate: new DateTimeInputUi(tomorrowDate),
  };
};
